<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
body {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTczMS4yMDcgNjQ5LjgwMkM5MzUuNDg0IDY0Mi4xNCAxNDgwIDMzNy4zMjUgMTQ4MCAxODAuODg4YzAtMTU2LjQzOC0zMDkuNzQ0LTM2LjA1NS03MjAtMzYuMDU1UzAtMTc0LjQ4MyAwIDEzNS4xNDRjMCAzMDkuNjI3IDUyNi45MyA1MjIuMzIgNzMxLjIwNyA1MTQuNjU4eiIgZmlsbD0iI0Y2RjhGQSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
  background-position-x: 20vw;
  background-position-y: -10vh;
  background-repeat: no-repeat;
  background-size: cover;
  color: #222;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  min-height: 100vh;
  margin: 0;
}
.footer,
.footer a {
  font-size: 16px !important;
}
.footer {
  display: block;
  padding: 16px 0;
}
h1,
h2 {
  font-family: Heebo, sans-serif;
}

a {
  color: #65638f;
  text-decoration: none;
  background-color: transparent;
}
section {
  display: block;
}
.container {
  margin: auto;
  max-width: 960px;
  padding: 0 30px;
}
.container-desktop {
  margin: auto;
  max-width: 960px;
  padding: 0 30px;
}
img {
  border-style: none;
}
</style>
